.header {
  display: flex;
  justify-content: space-between;
  z-index: 50;
  transition: all 0.2s ease-in-out;
  margin-bottom: 120px;
  a {
    text-decoration: none;
    color: inherit;
  }
  .logo {
    position: relative;

    width: 150px;
    cursor: pointer;
    height: 150px;

    img {
      border-radius: 50%;
      object-fit: contain;
      width: 100% !important;
      position: relative !important;
      height: unset !important;
    }
  }
  .searchIcon {
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .navItems {
    position: relative;
    cursor: pointer;
    text-align: center;
    font-size: 24px;
    color: #000201;

    &::after,
    &::before {
      transition: all 0.2s;
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0%;
      content: "";
      color: transparent;
      background: #858585;
      height: 1px;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1685px) {
  .header {
    .navItems {
      font-size: 20px;
    }
    .logo {
      width: 120px;
      height: 120px;
    }
  }
}
@media (max-width: 1350px) {
  .header {
    .logo {
      width: 110px;
      height: 110px;
    }
    .navItems {
      font-size: 18px;
    }
  }
}

@media (max-width: 1000px) {
  .header {
    .navItems {
      font-size: 16px;
    }
  }
}

@media (max-width:900px) {
  .header {
    .logo {
      width: 100px;
      height: 100px;
    }
  }
}
