@import url("https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap");

*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  scroll-behavior: smooth;
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  color: #000000;
  margin: 0;
  padding: 0;
  background: rgba(235, 235, 235, 0.43);
  font-family: "Cardo", serif;
  overflow-x: hidden;
}

.swiper {
  overflow: visible !important;
}
