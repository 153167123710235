.swiperContainer {
  margin-top: 100px;
  @media (max-width: 1000px) {
    margin-bottom: 80px;
  }
  .odd {
    transform: translateY(-70px);
    @media (max-width: 1650px) {
      transform: translateY(-50px);
    }
    @media (max-width: 1350px) {
      transform: translateY(-30px);
    }
  }
  .even {
    transform: translateY(70px);
    @media (max-width: 1650px) {
      transform: translateY(50px);
    }
    @media (max-width: 1350px) {
      transform: translateY(30px);
    }
  }
}
