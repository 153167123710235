.team {
  h3 {
    font-size: 48px;
    line-height: 87px;
    margin: 0 0 64px 0;
    letter-spacing: 0.345112px;
    font-weight: 700;
  }
  .grid {
    display: flex;
    justify-content: space-between;

    margin: 0 0 24px 0;

    .item {
      display: flex;
      justify-content: flex-start;
    }
  }
}

@media (max-width: 1650px) {
  .team {
    h3 {
      font-size: 36px;
      margin: 0 0 40px 0;
    }
  }
}

@media (max-width: 1350px) {
  .team {
    h3 {
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 1000px) {
  .team {
    // margin: 100px 0;
    .grid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    h3 {
      text-align: center;
      font-size: 36px;
    }
    p {
      font-size: 16px;
    }
  }

  .team {
    .grid {
      grid-template-columns: 1fr;
      margin: 0 0 20px 0;
      .item {
        justify-content: center;
      }
    }
  }
}
