.textCard {
  border-radius: 80px;
  box-shadow: 2px 4px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 70px 85px;
  position: relative;
  max-width: 750px;
  height: 100%;
  @media (max-width: 1000px) {
    max-width: 60vw;
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    text-transform: uppercase;
    color: #979797;
    margin: 0;
    padding: 0;
  }
  p {
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
  }
  .top {
    position: absolute;
    top: -4px;
    right: -4px;
    border-color: inherit;
    border-top: 12px solid;
    border-right: 12px solid;
    border-top-right-radius: 80px;
    width: 18%;
    height: 25%;
    border-color: rgba(112, 48, 160, 1);
    color: rgba(112, 48, 160, 1);
    &.blue {
      border-color: #0976b4;
      color: #0976b4;
      .round {
        background-color: #0976b4;
      }
    }
    .round {
      position: absolute;
      top: -12px;
      right: 94%;
      background-color: rgba(112, 48, 160, 1);

      width: 12px;
      height: 12px;
      border-radius: 50%;

      &:nth-of-type(2) {
        right: -12px;
        top: 94%;
      }
    }
    &:nth-last-child(2) {
      transform: rotate(180deg);
      bottom: -4px;
      left: -4px;
      top: unset;
    }
  }
}

@media (max-width: 1685px) {
  .textCard {
    padding: 36px 46px;
    border-radius: 50px;

    .top {
      border-top: 10px solid;
      border-right: 10px solid;
      border-top-right-radius: 50px;
      .round {
        width: 10px;
        height: 10px;
        top: -10px;
        &:nth-of-type(2) {
          right: -10px;
          top: 94%;
        }
      }
    }
    h1 {
      font-size: 24px;
      line-height: 16px;
    }
    p {
      font-size: 28px;
      line-height: 62px;
    }
  }
}
@media (max-width: 1350px) {
  .textCard {
    padding: 36px 46px;
    border-radius: 42px;
    .top {
      border-top: 6px solid;
      border-right: 6px solid;
      border-top-right-radius: 42px;
      .round {
        width: 6px;
        height: 6px;
        top: -6px;
        &:nth-of-type(2) {
          right: -6px;
          top: 94%;
        }
      }
    }
    h1 {
      font-size: 20px;
      line-height: 10px;
    }
    p {
      font-size: 24px;
      line-height: 50px;
    }
  }
}

@media (max-width: 600px) {
  .textCard {
    padding: 28px;
    p {
      font-size: 16px;
      line-height: 36px;
    }
    .top {
      .round {
        &:nth-of-type(2) {
          top: 97%;
        }
      }
    }
  }
}
