.footer {
  display: flex;
  padding: 100px 0;
  border-top: 1px solid #c8c8c8;
  margin-top: 20vh;

  main {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 16px;
    flex: 2;
    .logo {
      width: 100px;
      height: 100px;
      img {
        border-radius: 50%;
        object-fit: contain;
        width: 100% !important;
        position: relative !important;
        height: unset !important;
      }
    }
    .description {
      font-size: 16px;
      line-height: 36px;
      max-width: 400px;
      margin-bottom: 35px;
      color: #404040;
    }
    .socials {
      display: flex;
      gap: 10px;
      // cursor: pointer;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // width: 36px;
      // height: 36px;
      // border-radius: 50%;
      // background: #0976b4;
      // &:nth-child(2) {
      //   background: #55acee;
      // }
      // &:last-child {
      //   background: #3b5998;
      // }
      .social {
        font-size: 22px;
      }
    }
  }
  .listsContainer {
    display: flex;
    flex: 5;
    justify-content: space-around;
    margin-top: 45px;

    @media (max-width: 900px) {
      flex-direction: column;
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 40px;
      li {
        &.title {
          position: relative;
          font-weight: 700;
          font-size: 20px;
          width: fit-content;
          cursor: pointer;
          &::after,
          &::before {
            transition: all 0.2s;
          }
          &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 0%;
            content: "";
            color: transparent;
            background: #858585;
            height: 1px;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
          @media (max-width: 600px) {
            font-size: 16px;
          }
        }
        &.item {
          position: relative;
          font-size: 16px;
          width: fit-content;
          cursor: pointer;
          @media (max-width: 900px) {
            display: none;
          }
          &::after,
          &::before {
            transition: all 0.2s;
          }
          &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 0%;
            content: "";
            color: transparent;
            background: #858585;
            height: 1px;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1685px) {
  .footer {
    margin-top: 10vh;
    padding: 80px 0;
    main {
      .logo {
        width: 80px;
        height: 80px;
      }
      .description {
        font-size: 14px;
      }
      .socials {
        .item {
          width: 32px;
          height: 32px;
          svg {
            font-size: 18px;
          }
        }
      }
    }
    .listsContainer {
      ul {
        li {
          &.title {
            font-size: 18px;
          }
          &.item {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 1350px) {
  .footer {
    padding: 60px 30px;
    .listsContainer {
      ul {
        gap: 20px;
        li {
          &.title {
            font-size: 18px;
          }
          &.item {
            font-size: 14px;
          }
        }
      }
    }
    main {
      .logo {
        width: 75px;
        height: 75px;
      }
      .description {
        font-size: 16px;
      }
      .socials {
        .item {
          width: 30px;
          height: 30px;
          svg {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    display: none;
  }
}
