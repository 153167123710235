.contactUs {
  border-radius: 40px;

  display: flex;
  flex-direction: column;
  gap: 25px;

  .iconsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    gap: 10px;

    .icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 9px;
      .iconContainer {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: 0.872549px 1.7451px 13.0882px rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.237);
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .info {
        cursor: pointer;
        text-decoration: underline;
        font-size: 21px;
        color: black;
        max-width: 150px; /* set the maximum width */
        overflow: hidden;
        text-overflow: ellipsis; /* add three dots when text overflows */
        white-space: nowrap;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        .info {
          transition: all 0.3s ease-in-out;

          text-overflow: unset;
          max-width: fit-content;
        }
      }
    }
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .firstInputs {
      display: flex;
      gap: 20px;
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
  .startIcon {
    position: relative;
    display: flex;
    cursor: pointer;
    margin-left: -8px;
    box-shadow: 0px 9.48837px 9.48837px rgba(0, 0, 0, 0.25);
    background-color: rgba(9, 118, 180, 1);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    color: "white";
    justify-content: center;
    align-items: center;
    & svg {
      font-size: 48px;
      color: white;
    }
    .content {
      display: none;
      position: absolute;
      background-color: white;
      display: flex;
      align-items: center;
      border-radius: 100px;
      padding: 0 32px;
      top: -5px;
      width: 352px;
      height: 110px;
      font-size: 36px;
      font-weight: 700;
      line-height: 49px;
      justify-content: right;
      left: -5px;
      z-index: -1;
      box-shadow: 0.8725489974021912px 1.7450979948043823px 13.088234901428223px
        0px #0000001a;
    }
  }
}

@media (max-width: 1685px) {
  .contactUs {
    .startIcon {
      width: 80px;
      height: 80px;
      .content {
        padding: 0 28px;
        width: 280px;
        height: 95px;
        font-size: 28px;
      }
    }
  }
}

@media (max-width: 1350px) {
  .contactUs {
    .inputs {
      gap: 12px;
    }
    .iconsContainer {
      margin-bottom: 0;
      .icon {
        .info {
          font-size: 12px;
          max-width: 70px;
        }
        .iconContainer {
          width: 30px;
          height: 30px;
          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
    .startIcon {
      width: 54px;
      height: 54px;
      & svg {
        font-size: 32px;
      }
      .content {
        width: 180px;
        height: 65px;
        font-size: 16px;
      }
    }
  }
}
