.galleryContainer {
  .gallery {
    .pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10vh;
    }
    margin-bottom: 20vh;
    h3 {
      margin-bottom: 40px;
      font-size: 48px;
      font-weight: 400;
    }
    .grid {
      display: grid;
      grid-gap: 20px;
      row-gap: 70px;
      grid-template-columns: repeat(auto-fit, minmax(calc(24% - 10px), 1fr));
    }
  }
  .galleryDetailed {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 120px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    .main {
      width: 100%;

      .bannerContainer {
        width: 100%;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 50px;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.055);
        }
      }

      h3 {
        font-size: 40px;
        font-weight: 400;
        letter-spacing: -1.32327px;
        @media (max-width: 1000px) {
          font-size: 24px;
          text-decoration: underline;
        }
      }
      p {
        color: rgba(56, 52, 61, 1);
        margin: 5px 0;
        font-size: 28px;
        @media (max-width: 1000px) {
          font-size: 18px;
        }
      }
      h4 {
        font-size: 36px;
        font-weight: 400;
        letter-spacing: -1.32327px;
        @media (max-width: 1000px) {
          font-size: 20px;
          text-decoration: underline;
        }
      }
    }
    .sub {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 32px;
      @media (max-width: 1000px) {
        display: none;
      }
      .imageContainer {
        position: relative;
        img {
          position: relative !important;
          width: 100%;
          height: auto;
          max-height: 275px;
          border-radius: 25px;
          object-fit: cover;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.055);
        }
        // &:hover {
        //   .title {
        //     opacity: 1;
        //   }
        //   .layer {
        //     opacity: 1;
        //   }
        // }
        // .title {
        //   opacity: 0;
        //   margin: 0;
        //   font-weight: 400;
        //   position: absolute;
        //   bottom: 32px;
        //   z-index: 2;
        //   font-size: 32px;
        //   letter-spacing: -1.29976px;
        //   color: white;
        //   right: 50%;
        //   width: 100%;
        //   text-align: center;
        //   transform: translateX(50%);
        //   transition: all 0.2s ease-in-out;
        // }
        // .layer {
        //   transition: all 0.2s ease-in-out;
        //   opacity: 0;
        //   background: linear-gradient(
        //     180deg,
        //     rgba(0, 0, 0, 0.6) 22.4%,
        //     rgba(0, 0, 0, 0) 100%
        //   );

        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 25px;
        // }
      }
    }
  }
  h4 {
    font-size: 36px;
    font-weight: 400;
    letter-spacing: -1.32327px;
    @media (max-width: 1000px) {
      font-size: 20px;
      text-decoration: underline;
    }
  }
  .subImages {
    display: flex;
    gap: 15px;
    margin-bottom: 45px;
    flex-wrap: wrap;
    @media (max-width: 1000px) {
      // flex-direction: column;
      // align-items: center;
      // width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 20px;
    }
    .imgContainer {
      position: relative;
      cursor: pointer;
      display: flex;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.055);
      border-radius: 25px;

      .subImage {
        flex: 1;
        position: relative !important;
        height: auto;
        object-fit: cover;
        border-radius: 25px;
        max-height: 300px;
        @media (max-width: 1000px) {
          max-height: 200px;
        }
      }
      &:hover {
        .title {
          opacity: 1;
        }
        .layer {
          opacity: 1;
        }
      }
      .title {
        opacity: 0;
        margin: 0;
        font-weight: 400;
        position: absolute;
        bottom: 32px;
        z-index: 2;
        font-size: 32px;
        letter-spacing: -1.29976px;
        color: white;
        right: 50%;
        width: 100%;
        text-align: center;
        transform: translateX(50%);
        transition: all 0.2s ease-in-out;
      }
      .layer {
        transition: all 0.2s ease-in-out;
        opacity: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.35) 22.4%,
          rgba(0, 0, 0, 0) 100%
        );

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 25px;
      }
    }
  }
}
@media (max-width: 1650px) {
  .galleryContainer {
    .galleryDetailed {
      gap: 70px;
      .sub {
        .imageContainer {
          .title {
            font-size: 16px;
          }
        }
      }
      .main {
        .bannerContainer {
          img {
            // max-height: 600px;
          }
        }
        .subImages {
          .subImage {
            max-height: 20vh;
          }
        }
        h3 {
          font-size: 36px;
        }
        p {
          font-size: 28px;
        }
        h4 {
          font-size: 30px;
        }
      }
    }
    .gallery {
      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc(24% - 10px), 1fr));
        grid-gap: 20px;
        row-gap: 70px;
      }
      h3 {
        font-size: 24px;
      }
    }
  }
}
@media (max-width: 1350px) {
  .galleryContainer {
    h4 {
      font-size: 28px;
    }
    .galleryDetailed {
      gap: 45px;
      .sub {
        .imageContainer {
          .title {
            font-size: 16px;
          }
        }
      }
      .main {
        // .subImages {
        //   .subImage {
        //     max-height: 20vh;
        //   }
        // }
        h3 {
          font-size: 32px;
        }
        p {
          font-size: 24px;
        }
        h4 {
          font-size: 28px;
        }
      }
    }
    .gallery {
      .grid {
      }
      h3 {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .galleryContainer {
    h4 {
      font-size: 26px;
    }
    .galleryDetailed {
      display: flex;
      flex-direction: column;
      .main {
        h3 {
          font-size: 26px;
        }
      }
    }
    margin: 0;
    .gallery {
      .grid {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 25px;
      }
      h3 {
        text-align: center;
      }
    }
  }
}
