.Home {
  .main {
    display: flex;

    flex-direction: column;
    gap: 180px;
    .content {
      .item {
        h1 {
          font-size: 1.8em;
        }
        p {
          font-size: 22px;
        }
      }
    }
  }
  .flexContainer {
    display: flex;
    flex-direction: column;
    position: relative;

    .logoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      &.middle {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          right: 70%;
          top: 14%;
          z-index: -68;
          width: 386px;
          height: 90px;
          background: url(../../assets/centers/line1.svg);
        }
        &::after {
          content: "";
          position: absolute;
          left: 70%;
          top: 14%;
          z-index: -68;
          width: 386px;
          height: 90px;
          background: url(../../assets/centers/line1.svg);
        }
        .logoName {
          color: #7030a0;
        }
      }

      .logo {
        margin-bottom: 25px;
        width: 210px;
        height: 210px;
        padding: 30px;
        border-radius: 50%;
        background: linear-gradient(
          26deg,
          rgba(160, 212, 248, 0.088) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(180, 215, 240, 0.07) 100%
        );
        background-color: white;
        // border: 1px solid rgba(219, 219, 234, 0.211);
        box-shadow: 0.5450801253318787px 1.0901602506637573px
          8.176201820373535px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          position: relative !important;
          object-fit: contain;
        }
      }
      .logoName {
        cursor: pointer;
        font-size: 34px;
        font-weight: 700;
        color: #1aabae;
      }
      .logoDescription {
        text-align: center;
        font-size: 20px;
        color: rgba(56, 52, 61, 1);
      }
    }
    .imageContainer {
      margin-right: 55px;
      margin-left: 60px;
    }

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: flex-end;
      &::after {
        content: " ";
        position: absolute;
        width: 893px;
        height: 263px;
        background: url(../../assets/mainLine.svg);
        bottom: 0;
        z-index: -2;
      }
      .headingGroup {
        display: inline-flex;
        flex-wrap: wrap;
        // justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        h1 {
          margin: 0;
          padding: 0;
          font-weight: 700;
          font-size: 64px;
          color: rgba(56, 52, 61, 1);
        }
      }
      p {
        color: rgba(129, 129, 129, 1);
        font-size: 24px;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    gap: 130px;
  }
  .title {
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 60px;
    &:last-of-type {
      font-size: 64px;
    }
  }
  .trustees {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    font-size: 64px;
    position: relative;
    height: 95vh;
    @media (max-width: 1350px) {
      transform: scale(0.85);
    }
    @media (max-width: 900px) {
      height: auto;
    }
    .itemOne {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .one {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          z-index: -1;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
          width: 365px;
          height: 280px;
          background: url(../../assets/trustees/line1.svg);
        }
      }
      .two {
        transform: translateY(-70px);
      }
    }
    .itemTwo {
      display: flex;
      width: 100%;
      justify-content: space-between;
      max-width: 475px;

      .info {
        width: 100%;
        position: relative;
        text-align: center;
        span {
          display: inline-block;
          white-space: nowrap;
          @media (max-width: 1200px) {
            white-space: normal;
          }
          &::after {
            content: "Trustees";
            color: rgba(9, 118, 180, 1);
            font-weight: 700;
          }
        }
        &::after {
          content: "";
          width: 120px;
          height: 140px;
          position: absolute;
          right: -80px;
          top: -24px;
          background: url("../../assets/trustees/arrow1.svg");
        }
      }
    }

    .four {
      position: relative;
      &::before {
        content: "";
        z-index: -1;
        position: absolute;
        width: 86px;
        height: 60px;
        top: -5px;
        right: -10px;
        background: url(../../assets/trustees/arrow2.svg);
      }
    }

    .three {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .itemThree {
        display: flex;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 150px;
          height: 30px;
          left: -16px;
          top: 0;
          z-index: -1;
          background: url(../../assets/trustees/line2.svg);
        }
      }
    }
  }
  .partners {
    margin: 100px 0;
    display: flex;
    flex-direction: column;

    gap: 45px;
    .header {
      font-size: 48px;
      text-align: center;
      color: rgba(9, 118, 180, 1);
      font-weight: 700;
    }
    .items {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }

  .partnersChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 55px;
    .itemsContainer {
      display: flex;
      width: 100%;
      justify-content: center;
      &.first {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          bottom: -75%;
          right: 50%;
          width: 300px;
          height: 80px;
          background: url(../../assets/chart/1.svg);
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -75%;
          left: 50%;
          width: 300px;
          height: 80px;
          background: url(../../assets/chart/2.svg);
        }
      }
      &.second {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          bottom: -100%;
          left: 48%;
          width: 350px;
          height: 120px;
          background: url(../../assets/chart/4.svg);
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -100%;
          right: 10%;
          width: 350px;
          height: 120px;
          background: url(../../assets/chart/3.svg);
        }
      }

      &:nth-child(3) {
        margin-top: 35px;
        margin-bottom: 50px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          bottom: -100%;
          right: 50%;
          width: 685px;
          height: 100px;
          background: url(../../assets/chart/5.svg);
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -95%;
          left: 50%;
          width: 640px;
          height: 95px;
          background: url(../../assets/chart/6.svg);
        }
      }
      &:last-child {
        margin-top: -5px;
        @media (max-width: 1000px) {
          margin-top: -40px;
        }
      }
      .box {
        width: 240px;
        display: block;
      }
      .smBox {
        width: 130px;
        display: block;
      }
      .item {
        position: relative;
        z-index: 3;
        font-weight: 700;
        height: 100px;
        width: 340px;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        box-shadow: 0.9803921580314636px 1.9607843160629272px
          14.70588207244873px 0px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        background-color: rgb(9, 118, 180);
        // background-image: linear-gradient(
        //   348deg,
        //   rgb(37 187 225 / 58%) 0%,
        //   rgb(40 152 217 / 0%) 4%,
        //   rgb(9, 118, 180) 50%,
        //   rgb(37, 140, 199) 86%,
        //   rgb(37 187 225 / 12%)
        // );
        background-image: linear-gradient(
          62.42deg,
          rgba(255, 255, 255, 0.1) 20.61%,
          rgba(255, 255, 255, 0) 82.27%
        );
        backdrop-filter: blur(17.1569px);
      }
      .itemTwo {
        max-width: 475px;
        min-width: 200px;
        font-weight: 700;
        // background: linear-gradient(
        //   26deg,
        //   rgba(87, 184, 255, 0.13769257703081228) 0%,
        //   rgba(255, 255, 255, 1) 50%,
        //   rgba(87, 184, 255, 0.14329481792717091) 100%
        // );
        // background-color: white;
        background: linear-gradient(
          62.42deg,
          rgba(255, 255, 255, 0.1) 20.61%,
          rgba(255, 255, 255, 0) 82.27%
        );
        // filter: drop-shadow(0.872549px 1.7451px 13.0882px rgba(0, 0, 0, 0.1));
        backdrop-filter: blur(15.2696px);
        border-radius: 45px;
        padding: 0 50px;
        height: 90px;
        text-align: center;
        display: flex;
        align-items: center;
        color: rgba(9, 118, 180, 1);
        position: relative;
        box-shadow: 0.8725489974021912px 1.7450979948043823px
          13.088234901428223px 0px rgba(0, 0, 0, 0.1);
        .menu {
          position: absolute;
          right: -20px;
        }
        &.fourth {
          width: 390px;
          position: relative;
          &::before {
            top: -265%;
            z-index: -5;
            right: -17%;
            width: 330px;
            height: 229px;
            position: absolute;
            content: "";
            background: url(../../assets/chart/7.svg);
          }
        }
        &.fifth {
          position: relative;
          width: 390px;
          &::after {
            content: "";
            position: absolute;
            top: -265%;
            left: -17%;
            width: 336px;
            height: 235px;
            z-index: -5;
            background: url(../../assets/chart/8.svg);
          }
        }
      }
    }
  }
}

@media (max-width: 1685px) {
  .Home {
    .main {
      gap: 150px;
      .content {
        .item {
          h1 {
            font-size: 1.4em;
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
    .content {
      gap: 110px;
    }
    .flexContainer {
      .item {
        .headingGroup {
          h1 {
            font-size: 48px;
          }
        }
        h1 {
          font-size: 48px;
        }
        p {
          font-size: 20px;
        }
      }
      .logoContainer {
        .logo {
          width: 180px;
          height: 180px;
        }
        .logoName {
          font-size: 30px;
        }
        .logoDescription {
          font-size: 17px;
        }
      }
    }
    .title {
      font-size: 48px;
      &:last-of-type {
        font-size: 48px;
      }
    }
    .trustees {
      font-size: 52px;
      gap: 30px;
      height: 70vh;
    }
    .partners {
      margin: 80px 0;
    }
    .partnersChart {
      gap: 42px;
      .itemsContainer {
        &.first {
          &::before,
          &::after {
            transform: scale(0.8);
          }
        }
        &.second {
          &::before {
            left: 50%;
            transform: scale(0.8);
          }
          &::after {
            right: 8%;
            transform: scale(0.8);
          }
        }
        &:nth-child(3) {
          margin-top: 28px;
          margin-bottom: 40px;
          &::before {
            transform: scale(0.8);
            bottom: -115%;
            right: 47%;
          }
          &::after {
            transform: scale(0.8);
            bottom: -107%;
            left: 45%;
          }
        }
        .item {
          font-size: 18px;
          width: 300px;
          height: 80px;
        }
        .itemTwo {
          border-radius: 38px;
          padding: 0px 25px;
          height: 75px;
          font-size: 18px;
          &.fourth {
            width: 315px;
            &::before {
              transform: scale(0.8);
              top: -282%;

              right: -28%;
            }
          }
          &.fifth {
            width: 315px;
            &::after {
              transform: scale(0.8);
              top: -282%;
              left: -35%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1350px) {
  .Home {
    .main {
      .content {
        .item {
          h1 {
            font-size: 1.2em;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
    .content {
      gap: 80px;
    }
    .main {
      gap: 120px;
    }

    .title {
      font-size: 24px;
      &:last-of-type {
        font-size: 36px;
        margin-bottom: 30px;
      }
    }

    .flexContainer {
      .imageContainer {
        margin-right: 24px;
      }
      .item {
        &::after {
          content: " ";
          position: absolute;
          width: 470px;
          height: 140px;
          background: url(../../assets/smallSvgs/1.svg);
          bottom: 20px;
          z-index: -2;
        }
        h1 {
          font-size: 36px;
        }
        .headingGroup {
          h1 {
            font-size: 36px;
          }
        }
        p {
          font-size: 20px;
        }
      }
      .logoContainer {
        gap: 8px;
        &.middle {
          &::before {
            content: "";
            position: absolute;
            right: 75%;
            top: 25%;
            z-index: -68;
            width: 200px;
            height: 45px;
            background: url(../../assets//smallSvgs/2.svg);
          }
          &::after {
            content: "";
            position: absolute;
            left: 75%;
            top: 25%;
            z-index: -68;
            width: 200px;
            height: 45px;
            background: url(../../assets//smallSvgs/2.svg);
          }
        }
        .logo {
          width: 150px;
          height: 150px;
          margin-bottom: 12px;
        }
        .logoName {
          font-size: 24px;
        }
        .logoDescription {
          font-size: 18px;
          margin: 0;
        }
      }
    }
    .trustees {
      font-size: 42px;
      margin-top: -85px;
      .itemTwo {
        .info {
          span {
            font-size: 42px;
            white-space: nowrap;
          }
        }
      }
    }
    .partners {
      margin: 65px 0;
      .header {
        font-size: 34px;
      }
      .items {
        gap: 10px;
        .item {
          max-width: 160px;
        }
      }
    }
    .partnersChart {
      gap: 30px;
      .itemsContainer {
        .box {
          width: 125px;
        }
        &:nth-child(3) {
          margin: 15px 0;
          &::before {
            transform: none;
            width: 360px;
            height: 50px;
            position: absolute;
            left: 12%;
            bottom: -56%;
            background: url(../../assets/chart/small/5.svg);
          }
          &::after {
            transform: none;

            width: 360px;
            height: 50px;
            position: absolute;
            right: 12%;
            bottom: -58%;
            left: unset;
            background: url(../../assets/chart/small/6.svg);
          }
        }
        &.first {
          &::before {
            transform: none;

            content: "";
            position: absolute;
            bottom: -50%;
            right: 50%;
            width: 155px;
            height: 42px;
            background: url(../../assets/chart/small/1.svg);
          }
          &::after {
            transform: none;

            content: "";
            position: absolute;
            bottom: -50%;
            right: 50%;
            width: 155px;
            height: 42px;
            background: url(../../assets/chart/small/2.svg);
          }
        }
        &.second {
          &::before {
            transform: none;

            content: "";
            position: absolute;
            right: 50%;
            width: 185px;
            height: 65px;
            background: url(../../assets/chart/small/3.svg);
          }
          &::after {
            transform: none;

            content: "";
            position: absolute;
            right: 16%;
            width: 185px;
            height: 65px;
            background: url(../../assets/chart/small/4.svg);
          }
        }

        .item {
          width: 235px;
          height: 70px;
          font-size: 18px;
          padding: 10px 20px;
        }
        .itemTwo {
          height: 65px;
          font-size: 18px;
          &.fourth {
            width: unset;

            &::before {
              top: -120px;
              transform: none;

              right: -30%;
              width: 175px;
              height: 130px;
              background: url(../../assets/chart/small/7.svg);
            }
          }
          &.fifth {
            width: unset;
            &::after {
              top: -130px;
              transform: none;

              left: -30%;
              width: 175px;
              height: 120px;
              background: url(../../assets/chart/small/8.svg);
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  @media (max-width: 1200px) {
    .Home {
      .flexContainer {
        .logoContainer {
          &.middle {
            &::before,
            &::after {
              transform: scale(1.5);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .Home {
    .title {
      text-align: center;
    }
    .main {
      gap: 80px;
    }
    .content {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      .item {
        max-width: 100%;
      }
    }
    .flexContainer {
      .imageContainer {
        margin: unset;
      }
      .item {
        text-align: center;
        .headingGroup {
          justify-content: center;
        }
      }
      .logoContainer {
        .logoDescription {
          font-size: 18px;
          max-width: 50vw;
        }
        &.middle {
          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
    .trustees {
      flex-direction: column;
      margin-top: unset;
      height: auto;
      .itemTwo {
        width: auto;

        .info {
          &::after {
            z-index: -1;
            height: 140px;
            position: absolute;
            right: -0;
            transform: rotate(169deg);
          }
        }
      }
    }
    .partners {
      margin: 45px 0;
      .header {
        font-size: 34px;
      }
      .items {
        .item {
          max-width: 145px;
        }
      }
    }
    .partnersChart {
      gap: 65px;
      .itemsContainer {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        .item {
          width: 350px;
          height: 100px;
          font-size: 22px;
        }
        .itemTwo {
          height: 80px;
          font-size: 20px;
        }
        .box,
        .smBox {
          display: none;
        }
        &:nth-child(3),
        &.second {
          &::before {
            display: none;
          }
          &::after {
            bottom: -33%;
            transform: rotate(180deg);
            height: 140px;
            right: 30%;
            background: url(../../assets/trustees/arrow1.svg);
            width: 125px;
          }
        }

        &.first {
          &::before {
            display: none;
          }
          &::after {
            bottom: -62%;
            right: 30%;
            left: unset;
            transform: rotate(180deg);
            height: 140px;
            background: url(../../assets/trustees/arrow1.svg);
          }
        }

        .itemTwo {
          &.fifth {
            &::after,
            &::before {
              display: none;
            }
          }
          &.fourth {
            &::before {
              display: none;
              // bottom: unset;
              // top: -172%;
              // right: 18%;
              // left: unset;
              // transform: rotate(180deg);
              // height: 140px;
              // width: 120px;
              // background: url(../assets/trustees/arrow1.svg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .Home {
    .main {
      gap: 80px;
    }
    .title {
      text-align: center;
      font-style: 32px;
      font-weight: 700;
      &:last-of-type {
        font-size: 32px;
      }
    }
    .trustees {
      .itemTwo {
        .info {
          font-weight: 700;
          font-style: 36px;
        }
      }
    }
    .partners {
      margin-top: 48px;
      .header {
        font-size: 34px;
      }
      .items {
        .item {
          max-width: 130px;
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
      }
    }
    .partnersChart {
      .itemsContainer {
        display: flex;
        justify-content: center;
        gap: 14px;
        position: relative;

        .item,
        .itemTwo {
          position: relative;
          max-width: 300px;
          font-size: 18px;
          height: 80px;
        }
        &:nth-child(3) {
          margin: 0;
        }
        flex-direction: column;
        .box,
        .smBox {
          display: none;
        }
      }
    }
  }
}
