.swiperContainer {
  transform: translateX(-65px);
  @media (max-width: 1000px) {
    transform: none;
  }
  .serviceCard {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 1.0728855133px 2.1457710266px 16.0932846069px 0px
      rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    width: 295px;
    padding: 30px;
    height: 108px;
    overflow: hidden;
    @media (max-width: 650px) {
      width: 200px;
      padding: 10px;
      height: 108px;
    }
    h6 {
      color: rgba(26, 171, 174, 1);
      font-weight: 700;
      font-size: 16px;
      margin: 0 0 5px 0;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      margin: 0;
      letter-spacing: -0.342365px;
      @media (max-width: 650px) {
        font-style: 18px;
      }
    }
  }
}

@media (max-width: 1650px) {
  .swiperContainer {
    .serviceCard {
      width: 225px;
      height: 85px;
      padding: 22px;
      h6 {
        font-size: 14px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 650px) {
  .swiperContainer {
    transform: none;
  }
}
