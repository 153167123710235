.IACUC {
  position: relative;
  .header {
    display: flex;
    gap: 180px;
    justify-content: flex-start;
    .description {
      display: flex;
      flex-direction: column;
      max-width: 800px;
      margin-top: 60px;
      position: relative;
      @media (max-width: 1650px) {
        max-width: 650px;
      }
      &::after {
        content: " ";
        position: absolute;
        width: 900px;
        height: 275px;
        bottom: -75px;
        background: url(../../assets/mainLine.svg);
        @media (max-width: 1000px) {
          width: 600px;
        }
        @media (max-width: 650px) {
          display: none;
        }
      }
      h2 {
        margin: 10px 0;
        font-weight: 700;
        &::after {
          content: " IACUC";
          color: rgb(26, 171, 174);
        }
        &:first-child {
          font-size: 48px;
        }
        font-size: 64px;
        // &:nth-child(2) {
        //   color: rgb(26, 171, 174);
        //   font-size: 48px;
        // }
      }
      p {
        color: rgba(151, 151, 151, 1);
      }
    }
  }
  .vision {
    display: flex;
    flex-direction: column;

    gap: 20px;
    h3 {
      font-weight: 400;
      font-size: 64px;
    }
    .content {
      display: flex;
      gap: 130px;
    }
  }
  .meeting {
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 60px;
    }

    .meetingItems {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 55px;
      width: 100%;
      .item {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        .link {
          font-size: 24px;
          color: #979797;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .application {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 48px;
      font-weight: 700;
      margin: 30px 0 0 0;
    }
    p {
      font-size: 24px;
      margin: 10px 0;
      letter-spacing: -0.639376px;
    }
    .applicationItem {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: 1685px) {
  .IACUC {
    .header {
      // gap: 165px;
      .description {
        margin-top: 45px;
        &::after {
          transform: scale(0.8);
          right: -25%;
          bottom: -60px;
        }
        h2 {
          &:first-child {
            font-size: 36px;
          }
          &:nth-child(2) {
            font-size: 36px;
          }
        }
        p {
          font-size: 20px;
        }
      }
    }
    .vision {
      gap: 0;
      h3 {
        font-size: 40px;
      }
      .content {
        gap: 110px;
      }
    }
    .meeting {
      h3 {
        font-size: 40px;
        margin-bottom: 0;
      }
      .meetingItems {
        .item {
          .link {
            font-size: 20px;
          }
        }
      }
    }
    .application {
      .applicationItem {
        gap: 120px;
      }
      p {
        font-size: 20px;
      }
      h3 {
        font-size: 40px;
      }
    }
  }
}
@media (max-width: 1350px) {
  .IACUC {
    .header {
      gap: 90px;
      .description {
        margin-top: 30px;
        &::after {
          transform: scale(0.75);
          right: -32%;
          bottom: -35%;
        }
        h2 {
          &:first-child {
            font-size: 32px;
          }
          &:nth-child(2) {
            font-size: 32px;
          }
        }
        p {
          font-size: 16px;
        }
      }
    }
    .vision {
      gap: 0;
      h3 {
        font-size: 32px;
      }
      .conent {
        gap: 80px;
      }
    }
    .meeting {
      h3 {
        font-size: 32px;
      }
    }
    .application {
      p {
        font-size: 16px;
      }
      h3 {
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .IACUC {
    margin: 0;
    .header {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      .description {
        &::after {
          left: 0;
          bottom: -41%;
        }
      }
      h2 {
        text-align: center;
      }
    }
    .vision {
      h3 {
        text-align: center;
      }
      .content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        .item {
          align-items: center;
          max-width: 100%;
        }
      }
    }
    .meeting,
    .application {
      h3 {
        text-align: center;
        margin-bottom: 20px;
      }
      .meetingItems {
        .item {
          // display: flex;
          flex-direction: column;
          gap: 25px;
          .link {
            font-size: 14px;
            text-align: center;
            word-wrap: break-word;
            max-width: 90vw;
          }
        }
      }
      .applicationItem {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
      }
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
    .meeting {
      gap: 0;
    }
  }
}
@media (max-width: 650px) {
  .IACUC {
    .vision {
      h3 {
        font-size: 36px;
      }
    }
    .header {
      .description {
        h2 {
          font-size: 36px;
        }
        p {
          font-size: 20px;
        }
      }
    }

    .meeting {
      h3 {
        font-size: 32px;
        margin: 0;
        text-align: center;
      }
    }
    .application {
      gap: 20px;
      .applicationItem {
        gap: 0;
      }
      .icons {
        .icon {
          width: 50px;
          height: 50px;
        }
      }
      h3 {
        font-size: 24px;
      }
      p {
        text-align: center;
      }
    }
  }
}
