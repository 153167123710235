.accordion {
  max-width: 660px;
  @media (max-width: 1685px) {
    max-width: 470px;
  }
  @media (max-width: 1350px) {
    max-width: 420px;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  ul {
    list-style: none;
    li {
      margin: 24px 0 24px 40px;
      font-size: 20px;
      font-weight: 700;
      position: relative;
      @media (max-width: 1685px) {
        margin: 24px 0 24px 20px;
        font-size: 16px;
      }
      @media (max-width: 1350px) {
        margin: 24px 0 24px 10px;
        font-size: 14px;
      }
      @media (max-width: 1000px) {
        margin: 24px 0 24px 30px;
      }
    }
  }

  ul li::before {
    position: absolute;
    z-index: 5;
    content: "";
    background-color: #7030a0;
    font-weight: bold;
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    @media (max-width: 1685px) {
      width: 20px;
      height: 20px;
    }
    @media (max-width: 1350px) {
      width: 15px;
      height: 15px;
    }
    @media (max-width: 1000px) {
      width: 8px;
      height: 8px;
      left: -25px;
    }
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 650px) {
  .accordion {
    ul {
      padding: 0;
      li {
        font-size: 16px;
      }
    }
  }
}
