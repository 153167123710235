.personalCard {
  overflow: hidden;
  // max-width: 550px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  box-shadow: 1.6797258853912354px 3.3594517707824707px 25.195884704589844px 0px
    #0000001a;
  background-color: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 95px;
  height: 204px;
  white-space: nowrap;
  width: fit-content;
  @media (max-width: 650px) {
    height: 120px;
    padding: 8px;
  }
  &.small {
    height: 170px;
    background-color: rgba(255, 255, 255, 0.513);
    @media (max-width: 650px) {
      padding: 8px;

      height: 100px;
    }
  }
  .icon {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #e1e2e2;
      font-size: 46px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;

    .name {
      font-weight: 700;
      font-size: 32px;
      line-height: 34px;
      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
    .job {
      font-size: 26px;
      @media (max-width: 600px) {
        font-size: 18px;
      }
      line-height: 28px;
    }
  }
}

@media (max-width: 1685px) {
  .personalCard {
    height: 145px;
    &.small {
      height: 145px;
    }
    .info {
      .name {
        font-size: 24px;
      }
      .job {
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
}

@media (max-width: 1350px) {
  .personalCard {
    padding: 8px;
    .icon {
      svg {
        font-size: 32px;
      }
    }
    &.small {
      height: 110px;
      gap: 10px;
      .info {
        .name {
          font-size: 16px;
        }
        .job {
          font-size: 18px;
          line-height: 16px;
        }
      }
    }
    height: 160px;

    .info {
      .name {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 600px) {
  .personalCard {
    height: 120px;
    gap: 10px;
    .info {
      .name {
        font-size: 16px;
      }
      .job {
        font-size: 14px;
      }
    }

    &.small {
      height: 140px;
      .info {
        .name {
          font-size: 16px;
        }
        .job {
          font-size: 14px;
        }
      }
    }
  }
}
