.uraf {
  position: relative;
  .header {
    display: flex;
    gap: 180px;
    justify-content: flex-start;
    .description {
      display: flex;
      flex-direction: column;
      max-width: 800px;
      margin-top: 60px;
      position: relative;
      @media (max-width: 1650px) {
        max-width: 650px;
      }
      &::after {
        content: " ";
        position: absolute;
        width: 900px;
        height: 275px;
        bottom: -75px;
        background: url(../../assets/mainLine.svg);

        @media (max-width: 600px) {
          display: none;
        }
      }
      h2 {
        margin: 10px 0;
        font-weight: 700;
        &:first-child {
          font-size: 48px;
        }
        font-size: 64px;
        &:nth-child(2) {
          color: rgba(26, 171, 174, 1);
          font-size: 48px;
        }
      }
      p {
        color: rgba(151, 151, 151, 1);
      }
    }
  }
  .vision {
    display: flex;
    flex-direction: column;

    gap: 20px;
    // margin: 300px 0;
    h3 {
      font-weight: 400;
      font-size: 64px;
    }
    .content {
      display: flex;
      gap: 130px;
    }
  }
  .services {
    display: flex;
    flex-direction: column;
    h3 {
      font-weight: 400;
      font-size: 64px;
      margin: 0 0 64px 0;
    }

    .sliderContainer {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }
  .price {
    display: flex;
    justify-content: space-between;
    // margin: 340px 0;
    gap: 120px;
    .btnContainer {
      width: 100%;
    }
    .description {
      display: flex;
      flex-direction: column;
      max-width: 890px;
      flex: 2;
      gap: 25px;
      h3 {
        font-weight: 400;
        font-size: 64px;
        line-height: 60px;
        margin: 0;
        letter-spacing: -0.639376px;
      }
      p {
        line-height: 60px;
        margin: 0;
        letter-spacing: -0.639376px;
        font-size: 24px;

        color: #000000;
      }
    }
    .media {
      position: relative;
      width: 550px;
      height: 550px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 650px) {
        display: none;
      }
      .border {
        position: absolute;
        right: -28px;
        top: -28px;
      }
      .image {
        border-radius: 50%;
      }
    }
  }
  .team {
    h3 {
      font-size: 64px;
      line-height: 87px;
      margin: 0 0 96px 0;
      letter-spacing: 0.345112px;
      font-weight: 400;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 24px;
      margin: 0 0 100px 0;
      .item {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

@media (max-width: 1685px) {
  .uraf {
    .header {
      // gap: 120px;
      .description {
        margin-top: 45px;
        &::after {
          transform: scale(0.8);
          right: -25%;
          bottom: -60px;
        }
        h2 {
          &:first-child {
            font-size: 36px;
          }
          &:nth-child(2) {
            font-size: 36px;
          }
        }
        p {
          font-size: 20px;
        }
      }
    }
    .vision {
      gap: 0;
      h3 {
        font-size: 40px;
      }
      .content {
        gap: 110px;
      }
    }
    .services {
      h3 {
        font-size: 40px;
        margin-bottom: 40px;
      }
    }
    .price {
      .description {
        h3 {
          font-size: 40px;
        }
        p {
          font-size: 20px;
          line-height: 36px;
          max-width: 650px;
        }
      }
      .media {
        width: 425px;
        height: 425px;
        .border {
          transform: scale(0.8);
          right: -55px;
          top: -80px;
        }
      }
    }
  }
}

@media (max-width: 1350px) {
  .uraf {
    .header {
      gap: 90px;
      .description {
        margin-top: 30px;
        &::after {
          transform: scale(0.75);
          right: -32%;
          bottom: -35%;
        }
        h2 {
          &:first-child {
            font-size: 32px;
          }
          &:nth-child(2) {
            font-size: 32px;
          }
        }
        p {
          font-size: 16px;
        }
      }
    }
    .vision {
      gap: 0;
      h3 {
        font-size: 32px;
      }
      .content {
        gap: 80px;
      }
    }
    .services {
      h3 {
        font-size: 32px;
        margin-bottom: 32px;
      }
    }
    .price {
      .description {
        h3 {
          font-size: 32px;
        }
        p {
          font-size: 16px;
          line-height: 32px;
        }
      }
      .media {
        width: 290px;
        height: 290px;
        .border {
          transform: scale(0.55);
          right: -88px;
          top: -142px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .uraf {
    margin: 0;
    .header {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      h2 {
        text-align: center;
      }
    }
    .vision {
      h3 {
        text-align: center;
      }
      .content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        .item {
          align-items: center;
          max-width: 100%;
        }
      }
    }
    .price {
      flex-direction: column;
      align-items: center;
      .btnContainer {
        display: flex;
        justify-content: center;
      }
      .description {
        h3 {
          text-align: center;
        }
      }
    }
    .team {
      .grid {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    }
    .services {
      h3 {
        text-align: center;
      }
    }
  }
}
@media (max-width: 1000px) {
  .uraf {
    .header {
      .description {
        &::after {
          right: 10%;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .uraf {
    .header {
      .description {
        h2 {
          font-size: 36px;
        }
        p {
          font-size: 20px;
        }
      }
    }
    .vision,
    .services,
    .price,
    .team {
      margin: 100px 0;
      h3 {
        text-align: center;
        font-size: 36px;
      }
      p {
        font-size: 16px;
      }
      .description {
        align-items: center;
      }
    }

    .team {
      .grid {
        grid-template-columns: 1fr;
      }
    }
  }
}
