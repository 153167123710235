.menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .header {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 15px 0;
      padding-right: 24px;
      @media (max-width: 1000px) {
        padding-right: 12px;
      }
      display: flex;
      gap: 16px;
      position: relative;
      .menu {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 650px) {
          right: -20px;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 700;
          font-size: 20px;
        }
        .title {
          font-size: 16px;
          color: #787878;
        }
      }
    }
  }
}

@media (max-width: 1685px) {
}

@media (max-width: 1350px) {
  .menu {
    .header {
      font-size: 20px;
      line-height: 18px;
    }
    ul {
      li {
        .info {
          .name {
            font-size: 18px;
          }
          .title {
            font-size: 16px;
          }
        }
      }
    }
  }
}
