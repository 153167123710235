.error {
  // font-family: "Righteous", cursive;
  color: #e1e1e1;
  width: 100%;
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    90deg,
    rgba(47, 54, 64, 1) 23%,
    rgba(24, 27, 32, 1) 100%
  );
}

.error__title {
  font-size: clamp(3em, 25vw, 10em);
  text-align: center;
}

.error__subtitle {
  font-size: 2em;
  text-align: center;
}

.error__description {
  opacity: 0.5;
  margin-bottom: 1rem;
  text-align: center;
}
a {
  text-decoration: none;
}

.error__button {
  min-width: 7em;
  // margin-top: 4rem;
  margin-right: 0.5em;
  padding: 0.5em 2em;
  outline: none;
  border: 2px solid #d2d6db;
  background-color: transparent;
  border-radius: 8em;
  color: #bfc3cb;
  cursor: pointer;
  transition-duration: 0.2s;
  font-size: 0.75em;
  // font-family: "Righteous", cursive;
}

.error__button:hover {
  color: #5f6266;
}

.error__button--active:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  color: white;
}
