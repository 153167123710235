.NoData {
  .error {
    // font-family: "Righteous", cursive;
    color: #707070;
    width: 100%;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .errorContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .errorTitle {
    font-size: clamp(3em, 25vw, 10em);
    text-align: center;
  }

  .errorSubtitle {
    font-size: 2em;
    text-align: center;
  }

  .errorDescription {
    opacity: 0.5;
    margin-bottom: 1rem;
    text-align: center;
  }
  a {
    text-decoration: none;
  }

  .errorButton {
    min-width: 7em;
    // margin-top: 4rem;
    margin-right: 0.5em;
    padding: 0.5em 2em;
    outline: none;
    border: 2px solid #3d3d3e;
    background-color: transparent;
    border-radius: 8em;
    color: #515151;
    cursor: pointer;
    transition-duration: 0.2s;
    font-size: 0.75em;
    // font-family: "Righteous", cursive;
  }
}
