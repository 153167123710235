.card {
  &.small {
    width: 340px;
    height: 390px;
    @media (max-width: 600px) {
      width: 250px;
      height: 290px;
    }
    .title {
      font-size: 24px;
      right: 50%;
      transform: translate(50%);
      @media (max-width: 600px) {
        font-size: 16px;
        margin-top: 10px;
        padding: 5px;
      }
    }
    .icon {
      width: 45px;
      height: 45px;
      svg {
        font-size: 18px;
      }
    }
  }
  width: 440px;
  height: 515px;
  border-radius: 35px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
  box-shadow: 0.8725489974021912px 1.7450979948043823px 13.088234901428223px 0px
    rgba(0, 0, 0, 0.1);

  &:hover {
    .title {
      opacity: 1;
    }
    .layer {
      opacity: 1;
    }
    .icon {
      opacity: 1;
    }
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 35px;
    box-shadow: 1.7608095407485962px 3.5216190814971924px 26.41214370727539px
      0px #0000001a;
  }
  .title {
    opacity: 0;
    margin: 0;
    font-weight: 400;
    position: absolute;
    top: 4%;
    z-index: 2;
    font-size: 32px;
    letter-spacing: 0.25px;
    color: white;
    width: 80%;
    transition: all 0.2s ease-in-out;
    right: 50%;
    transform: translate(50%);
  }
  .layer {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 22.4%,
      rgba(0, 0, 0, 0) 100%
    );

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 35px;
  }
  .icon {
    position: absolute;
    width: 60px;
    height: 60px;
    bottom: 64px;
    right: 48px;
    background-color: #0976b4;
    border-radius: 50%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 7px 5px -2px rgba(133, 133, 133, 0.75);

    svg {
      color: white;
      font-size: 26px;
      transform: translate(3px, 0);
    }
  }
}

@media (max-width: 1685px) {
  .card {
    width: 300px;
    height: 345px;
    .icon {
      width: 50px;
      height: 50px;
      right: 40px;
      bottom: 50px;
      svg {
        font-size: 20px;
        transform: translate(3px, 0);
      }
    }
    &.small {
      width: 300px;
      height: 350px;
    }
    .title {
      font-size: 24px;
      top: 5%;
    }
  }
}

@media (max-width: 1350px) {
  .card {
    width: 230px;
    height: 270px;
    .icon {
      width: 30px;
      height: 30px;
      right: 25px;
      bottom: 30px;
      svg {
        font-size: 16px;
        transform: translateX(-1px);
      }
    }
    &.small {
      width: 230px;
      height: 270px;
      .title {
        font-size: 16px;
        top: 15%;
      }
      .icon {
        width: 30px;
        height: 30px;
        right: 25px;
        bottom: 30px;
        svg {
          font-size: 16px;
          transform: translateX(-1px);
        }
      }
    }
    .title {
      font-size: 16px;
      top: 15%;
    }
  }
}

@media (max-width: 600px) {
  .card {
    &.small {
      width: 180px;
      height: 220px;
      .title {
        font-size: 14px;
        top: 4%;
      }
    }
  }
}
