.Toastify__toast {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}
@media (max-width: 600px) {
  .Toastify__toast-theme--light {
    font-size: 14px;
  }
  .Toastify__toast {
    width: 220px;
  }
  .Toastify__toast-container {
    display: flex;
    justify-content: center;
  }
}

/* .Toastify__toast--success {
  background-color: #4caf50;
}

.Toastify__toast--error {
  background-color: #f44336;
} */

/* .toastify-content {
  font-size: 16px;
  color: #fff;
  background-color: #333;
} */
