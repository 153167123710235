.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .formItem {
    display: flex;
    gap: 50px;
    @media (max-width: 1685px) {
      gap: 25px;
    }
    @media (max-width: 1350px) {
      gap: 20px;
    }
  }
  @media (max-width: 1685px) {
    gap: 30px;
  }
  @media (max-width: 1350px) {
    gap: 25px;
  }
}
