.CTCLAS {
  position: relative;
  .header {
    display: flex;
    gap: 180px;
    justify-content: flex-start;
    .description {
      display: flex;
      flex-direction: column;
      max-width: 800px;
      margin-top: 60px;
      position: relative;
      @media (max-width: 1650px) {
        max-width: 650px;
      }
      &::after {
        content: " ";
        position: absolute;
        width: 900px;
        height: 275px;
        bottom: -75px;
        background: url(../../assets/mainLine.svg);

        @media (max-width: 600px) {
          display: none;
        }
      }
      h2 {
        margin: 10px 0;
        font-weight: 700;
        &:first-child {
          font-size: 48px;
        }
        font-size: 64px;
        &:nth-child(2) {
          color: #7030a0;
          font-size: 48px;
        }
      }
      p {
        color: rgba(151, 151, 151, 1);
      }
    }
  }
  .vision {
    display: flex;
    flex-direction: column;

    gap: 20px;
    h3 {
      font-weight: 400;
      font-size: 64px;
    }
    .content {
      display: flex;
      gap: 130px;
    }
  }
  .training {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .item {
      .imageContainer {
        position: relative;
        width: 640px;
        height: 640px;
        .border {
          position: absolute;
          width: 344px;
          height: 588px;
          right: -18px;
          top: -33px;
        }
        .image {
          border-radius: 50%;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      h3 {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        font-size: 24px;
        max-width: 600px;
      }
      .trainingItems {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }
  }
  .consultation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      .imageContainer {
        position: relative;
        width: 675px;
        height: 675px;
        .border {
          position: absolute;
          width: 363px;
          height: 620px;
          top: -35px;
          left: -31px;
        }
        .image {
          border-radius: 50%;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      h3 {
        font-size: 64px;
        font-weight: 700;
        margin: 20px 0;
      }
      p {
        font-size: 24px;
        max-width: 600px;
      }
      .form {
        display: flex;
        flex-direction: column;
        gap: 50px;
        .formItem {
          display: flex;
          gap: 50px;
        }
      }
    }
  }
}

@media (max-width: 1685px) {
  .CTCLAS {
    .header {
      // gap: 165px;
      .description {
        margin-top: 45px;
        &::after {
          transform: scale(0.8);
          right: -25%;
          bottom: -60px;
        }
        h2 {
          &:first-child {
            font-size: 36px;
          }
          &:nth-child(2) {
            font-size: 36px;
          }
        }
        p {
          font-size: 20px;
        }
      }
    }
    .vision {
      gap: 0;
      h3 {
        font-size: 40px;
      }
      .content {
        gap: 110px;
      }
    }
    .training {
      .item {
        .imageContainer {
          width: 500px;
          height: 500px;
          .border {
            transform: scale(0.78);
            right: -47px;
            top: -88px;
          }
        }
        h3 {
          font-size: 36px;
        }
        p {
          font-size: 20px;
        }
      }
    }
    .consultation {
      .item {
        h3 {
          margin: 10px 0;
        }
        .form {
          gap: 38px;
        }
        .imageContainer {
          width: 500px;
          height: 500px;
          margin-right: 115px;
          .border {
            transform: scale(0.75);
            top: -103px;
            left: -68px;
          }
        }
        h3 {
          font-size: 36px;
        }
        p {
          font-size: 20px;
        }
      }
    }
  }
}
@media (max-width: 1350px) {
  .CTCLAS {
    .header {
      gap: 90px;
      .description {
        margin-top: 30px;
        &::after {
          transform: scale(0.75);
          right: -32%;
          bottom: -35%;
        }
        h2 {
          &:first-child {
            font-size: 32px;
          }
          &:nth-child(2) {
            font-size: 32px;
          }
        }
        p {
          font-size: 16px;
        }
      }
    }
    .vision {
      .content {
        gap: 80px;
      }
      gap: 0;
      h3 {
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .CTCLAS {
    margin: 0;
    .header {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      h2 {
        text-align: center;
      }
    }
    .vision {
      h3 {
        text-align: center;
      }
      .content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        .item {
          align-items: center;
          max-width: 100%;
        }
      }
    }
    .training,
    .consultation {
      .item {
        p {
          text-align: center;
        }
        h3 {
          text-align: center;
        }

        .imageContainer {
          margin-right: 0;
        }
        .trainingItems {
          align-items: center;
          width: 100%;
        }
      }
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 70px;
    }
  }
}
@media (max-width: 1000px) {
  .CTCLAS {
    .training,
    .consultation {
      .imageContainer {
        transform: scale(0.9);
      }
    }
  }
}
@media (max-width: 600px) {
  .CTCLAS {
    .header {
      .description {
        h2 {
          font-size: 36px;
        }
        p {
          font-size: 20px;
        }
      }
    }
    .vision {
      margin: 100px 0;
      h3 {
        font-size: 36px;
        text-align: center;
      }
    }
    .consultation,
    .training {
      margin: 20px 0;
      .item {
        .form {
          .formItem {
            flex-direction: column;
          }
        }
        h3 {
          font-size: 36px;
          text-align: center;
        }
        p {
          font-size: 18px;
        }
      }
      .imageContainer {
        display: none;
      }
    }
  }
}
