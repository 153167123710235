.videoCard {
  border-radius: 50px;
  position: relative;

  .videoContainer {
    width: 620px;
    height: 700px;
    border-radius: 50px;
  }
  .image {
    border-radius: 50px;
    // object-fit: ;
    width: 100%;
    background-color: white;
    height: 100%;
  }
  .hide {
    opacity: 0;
    z-index: -1;
  }
  video {
    border-radius: 50px;
    background-color: white;
    height: 100%;
    width: 100%;
  }

  .box {
    position: absolute;
  }
  .one {
    width: 37%;
    height: 31%;
    top: -36px;
    right: -55px;
    z-index: -1;
    background: rgba(42, 47, 105, 0.4);
    border-radius: 72px;
    @media (max-width: 1600px) {
      right: -10%;
      border-radius: 60px;
    }
  }
  .two {
    width: 25%;
    height: 30%;
    bottom: 176px;
    left: -67px;
    z-index: -1;
    background: rgba(231, 193, 111, 0.4);
    border-radius: 50px;
    @media (max-width: 1600px) {
      left: -10%;
    }
  }
  .three {
    width: 40%;
    height: 25%;
    z-index: -1;
    right: 91px;
    bottom: -61px;
    background: rgba(138, 190, 82, 0.4);
    border-radius: 50px;
    @media (max-width: 1600px) {
      bottom: -10%;
    }
  }
}
.imageCard {
  border-radius: 40px;
  position: relative;
  width: 500px;
  height: 570px;
  margin-left: 50px;
  // @media (width<1200px) {
  //   width: 350px;
  //   height: 450px;
  //   margin-left: 0;
  // }
  @media (max-width: 650px) {
    width: 280px;
    height: 270px;
  }
  img {
    object-fit: cover;
    border-radius: 40px;
    width: 100%;
    height: 100%;
  }
  .box {
    position: absolute;
  }
  .one {
    width: 170px;
    height: 170px;
    top: -47px;
    left: -55px;
    z-index: -1;
    background-color: #97d6d7;
    border-radius: 50%;
    @media (max-width: 650px) {
      width: 100px;
      height: 100px;
      left: -30px;
      top: -30px;
    }
  }
  .purple {
    background-color: #d0bede;
  }
  .two {
    width: 165.51px;
    height: 165.28px;
    background: rgba(231, 193, 111, 0.4);
    bottom: 161px;
    right: -67px;
    z-index: -1;
    border-radius: 50%;
    @media (max-width: 650px) {
      width: 100px;
      height: 100px;
      bottom: 215px;

      right: -32px;
    }
  }
  .three {
    width: 212px;
    height: 212.33px;
    left: 53.56px;
    bottom: -47.33px;
    border-radius: 50%;
    background: rgba(138, 190, 82, 0.4);
    z-index: -1;
    @media (max-width: 650px) {
      width: 120px;
      height: 120px;
      left: 25%;
    }
  }
}

@media (max-width: 1685px) {
  .videoCard {
    .videoContainer {
      width: 470px;
      height: 500px;
    }
  }
  .imageCard {
    width: 345px;
    height: 410px;
    margin-left: 40px;
    .one {
      width: 110px;
      height: 110px;
      top: -10%;
      left: -10%;
    }
    .two {
      width: 100px;
      height: 100px;
      bottom: 22%;
      right: -15%;
    }
    .three {
      width: 140px;
      height: 140px;
      left: 12%;
      bottom: -12%;
    }
  }
}

@media (max-width: 1350px) {
  .videoCard {
    border-radius: 25px;
    .one {
      border-radius: 40px;
      width: 33%;
    }
    .two {
      bottom: 20%;
    }
    .three {
      border-radius: 25px;
      right: 15%;
    }
    .image {
      border-radius: 25px;
    }
    .videoContainer {
      width: 325px;
      height: 365px;
    }
  }
  .imageCard {
    width: 265px;
    height: 300px;
    margin-left: 38px;
    .one {
      width: 90px;
      height: 90px;
      top: -10%;
      left: -10%;
    }
    .two {
      width: 85px;
      height: 85px;
      bottom: 22%;
      right: -15%;
    }
    .three {
      width: 110px;
      height: 110px;
      left: 12%;
      bottom: -12%;
    }
  }
}

@media (max-width: 1000px) {
  .videoCard {
    .videoContainer {
      width: 350px;
      height: 390px;
    }
  }
  .imageCard {
    margin: unset;
  }
}
@media (max-width: 600px) {
  .videoCard {
    .videoContainer {
      width: 320px;
      height: 350px;
    }
  }
}
