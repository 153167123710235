.application {
  display: flex;
  margin: 50px 0;
  padding: 35px 40px 35px 50px;
  border-radius: 20px;
  max-width: 760px;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 1.7240746021270752px 3.4481492042541504px 25.86111831665039px 0px
    #0000001a;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 18px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #1aabae;
  }

  .content {
    font-size: 40px;
    color: black;
    letter-spacing: -0.639376px;
  }
  .icons {
    display: flex;

    gap: 32px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 80px;
      height: 80px;
      box-shadow: 1.7240746021270752px 3.4481492042541504px 25.86111831665039px
        0px #0000001a;
      border-radius: 50%;
    }
    .download {
      background-color: #bdc4c5;
      pointer-events: none;
      svg {
        fill: white;
      }
    }
  }
}

@media (max-width: 1685px) {
  .application {
    margin: 38px 0;
    padding: 28px 30px 28px 34px;
    // max-width: 500px;
    .content {
      font-size: 24px;
    }
    .icons {
      .icon {
        width: 50px;
        height: 50px;
        svg {
          transform: scale(0.8);
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .application {
    max-width: 650px;
    margin: 5px 0;
  }
}

@media (max-width: 650px) {
  .application {
    padding: 10px 24px;

    &::before {
      width: 10px;
    }
    .content {
      font-size: 16px;
    }
    .icons {
      gap: 10px;
      .icon {
        width: 40px;
        height: 40px;
        svg {
          transform: scale(0.6);
        }
      }
    }
  }
}
